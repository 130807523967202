import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[
            `blog`,
            `gatsby`,
            `javascript`,
            `react`,
            `frontend developer`,
          ]}
        />
        <p>
          I'm a passionate web developer that can see the big picture. People
          over pixels. Technology and design practices will change over time,
          however the keys to successful user engagement will always remain
          constant. Deliver well thought out content and get out of the users
          way. This is achieved through allowing content to be; viewable on
          every device, quick to load (perceived load time over actual), and
          accessible to everyone.
        </p>

        <p>
          I have experience working on projects in a variety of roles. From UX
          to design to development, I have worked as part of an agile team to
          put the best product forward for a number of clients. These clients
          include: Granville Island Brewing, Preventable, SunRype, Encorp
          Return-It, Vancity, and Wanderoot Craft Cider.
        </p>

        <p>
          One of the things I love about the web is that it is constantly
          evolving by leaps and bounds. I am looking to be a part of a team that
          is committed to keeping in step.
        </p>
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
